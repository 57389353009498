import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../backend/common/api/http.service';
import { BREAKDOWN_KPIS_GROUPING_INFO_LIST } from '../interfaces/business/breakdown';
import { Segment } from '../interfaces/business/segment';
import { SimpleDateRange } from '../interfaces/common/date-range';

export interface IGetKpisParams {
  segment?: Segment;
  dateRange?: SimpleDateRange;
  planId: string;
  scenarios: Array<string>;
  interval: DateAggregationOption;
  kpisByDate: boolean;
  currency?: string;
}
export interface IGetKpisByDateParams extends IGetKpisParams {
}

export interface IGetAtomicKpisParams extends IGetKpisParams {
  groupingColumns: string[];
  uom?: string;
  kpiName: string;
}

export enum AtomicKPIs {
  DemandValue = 'demandValue',
  TradeExpenses = 'tradeExpenses',
  NetSalesValue = 'netSalesValue',
  GrossSalesValue = 'grossSalesValue',
  OrderQuantity = 'orderQuantity',
  DispatchQuantity = 'dispatchQuantity',
  COGS = 'cogs',
  DistributionCost = 'distributionCost',
  HandlingCost = 'handlingCost',
  StorageCost = 'storageCost',
  TransferCost = 'transferCost',
  CO2 = 'CO2',
  InboundCO2 = 'InboundCO2',
  StorageCO2 = 'StorageCO2',
  OutboundCO2 = 'OutboundCO2',
  UnconstrainedCLS = 'UnconstrainedCLS',
  ConstrainedCLS = 'ConstrainedCLS',
  UnconstrainedSalesOut = 'UnconstrainedSalesOut',
  ConstrainedSalesOut = 'ConstrainedSalesOut',
  ConstrainedSalesOutGSV = 'ConstrainedSalesOutGSV',
  UnconstrainedSalesOutGSV = 'UnconstrainedSalesOutGSV',
  UnconstrainedCLSGSV = 'UnconstrainedCLSGSV',
  ConstrainedCLSGSV = 'ConstrainedCLSGSV',
  SGACost = 'SGACost',
  FIEDemandValue = 'FIEDemandValue',
  GrossFIESales = 'GrossFIESales',
  FIETradeExpenses = 'FIETradeExpenses',
  Transport = 'Transport',
  StorageAndHandling = 'StorageAndHandling',
  Promotion = 'Promotion',
  MedicalAffairs = 'MedicalAffairs',
  Administration = 'Administration',
  NetFIESales = 'NetFIESales',
  GrossProfit = 'GrossProfit',
  LocalDemandValue = 'LocalDemandValue',
  LocalDemandVolume = 'LocalDemandVolume',
  GrossLocalSales = 'GrossLocalSales',
  LocalSalesVolume = 'LocalSalesVolume',
  DistributorLocalDemandValue = 'DistributorLocalDemandValue',
  DistributorLocalDemandVolume = 'DistributorLocalDemandVolume',
  DistributorGrossLocalSales = 'DistributorGrossLocalSales',
  DistributorLocalSalesVolume = 'DistributorLocalSalesVolume',
  LocalTradeExpenses = 'LocalTradeExpenses',
  FIEDemandVolume = 'FIEDemandVolume',
  FIESalesVolume = 'FIESalesVolume',
  LocalCaseFillRate = 'LocalCaseFillRate',
  FIECaseFillRate = 'FIECaseFillRate',
  NetLocalSales = 'NetLocalSales',
  COGSPercent = 'COGSPercent',
  GrossMargin = 'GrossMargin',
  SupplyChainCost = 'SupplyChainCost',
  DPM = 'DPM',
  DPMPercent = 'DPMPercent',
  DPMA = 'DPMA',
  DPMAPercent = 'DPMAPercent',
  EBITPercent = 'EBITPercent',
}

export interface IGetSpChartDataParams extends IGetKpisParams {
  interval: DateAggregationOption;
  kpisByDate: boolean;
}

@Injectable()
export class KpisService {
  constructor(private readonly httpService: HttpService) { }

  getKpis(params: IGetKpisParams) {
    return this.httpService.post('kpis', params);
  }

  getSpChart(params: IGetSpChartDataParams) {
    return this.httpService.post('kpis/chart', params);
  }

  getAtomicKpis(params: IGetAtomicKpisParams) {
    return this.httpService.post('kpis/atomic', params);
  }
}
